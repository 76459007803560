import { EnvelopeIcon } from "../../../../assets/svg";
import "./contact.s.scss";

export default function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="section-header">
          <h2 className="section-heading">Contact us</h2>
        </div>

        <div className="contact-content">
          <a className="contact-link" href="mailto:simba@likelion.net">
            <img src={EnvelopeIcon} alt="simba@likelion.net" />
            <span>simba@likelion.net</span>
          </a>
        </div>
      </div>
    </div>
  );
}
