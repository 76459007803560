import { AxiosResponse } from "axios";
import _ from "lodash";
import { Option } from "../interfaces";
import { SearchAutoCompletedResponse } from "../interfaces/home";
import axios from "./axiosConfig";

export const fetchSearchComplete = async (
  searchStr: string,
  page: number,
  pageSize: number
): Promise<Option[]> => {
  try {
    const response: AxiosResponse<SearchAutoCompletedResponse> =
      await axios.get(
        `job-titles?searchStr=${searchStr}&page=${page}&size=${pageSize}`
      );
    const mappedAutoComplete = _.map(response.data.content, (item) => ({
      label: item.name,
      value: item.name,
    }));
    return mappedAutoComplete;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
