import dayjs from "dayjs";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import parsePhoneNumberFromString, {
  CountryCode,
  PhoneNumber,
} from "libphonenumber-js";
import { Option } from "../interfaces";

const getLabelByValue = (
  options: Option[],
  value: number | string
): any | undefined => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : undefined;
};

const calculateDaysDiff = (date: string | number, isFullDay = false) => {
  const targetDate = dayjs(date);
  const currentDate = dayjs();

  const diffInDays = currentDate.diff(targetDate, "day");

  if (diffInDays < 1) {
    return "Today";
  } else {
    if (isFullDay) {
      return `${diffInDays} ${diffInDays > 1 ? "days" : "day"} ago`;
    } else {
      return `${diffInDays}d`;
    }
  }
};

const isTokenExpired = (token: string) => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp ? decoded.exp < currentTime : true;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const clearAllCookies = () => {
  const allCookies = Cookies.get();

  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};

const getUUIDFromURL = (url: string) => {
  const regex = /media-files\/([0-9a-fA-F-]+)/;
  const match = url?.match(regex);
  return match ? match[1] : null;
};

const formatPhoneNumberWithHyphens = (
  countryCode: CountryCode,
  phoneValue: string
) => {
  const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
    phoneValue,
    countryCode
  );

  if (phoneNumber) {
    return phoneNumber.formatNational().replace(/[^\d]/g, "-");
  } else {
    return "Invalid phone number";
  }
};

export {
  calculateDaysDiff,
  clearAllCookies,
  formatPhoneNumberWithHyphens,
  getLabelByValue,
  getUUIDFromURL,
  isTokenExpired,
};
