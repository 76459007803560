import { AxiosResponse } from "axios";
import _ from "lodash";
import { Option } from "../interfaces";
import { LocationResponse } from "../interfaces/home";
import axios from "./axiosConfig";

export const fetchListLocation = async (
  searchStr: string,
  page: number,
  pageSize: number
): Promise<Option[]> => {
  try {
    const formatStr = _.capitalize(_.toLower(_.trim(searchStr)));
    const response: AxiosResponse<LocationResponse> = await axios.get(
      `locations?searchStr=${formatStr}&page=${page}&size=${pageSize}`
    );
    const mappedLocation = _.map(response.data.content, (item) => ({
      label: _.compact([item.city, item.state, item.country]).join(", "),
      value: _.compact([item.city, item.state, item.country]).join(", "),
      id: [item.cityId, item.countryId],
    }));
    return mappedLocation;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};
