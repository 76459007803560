const ACCEPT_FILE_TYPES = ".doc,.docx,.pdf";

const MAX_FILE_SIZE = 1024 * 1024 * 2;

const MAX_FILE_SIZE_IMAGE = 1024 * 1024 * 2;

const bannedDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "live.com",
  "icloud.com",
  "aol.com",
  "mail.com",
  "yandex.com",
  "protonmail.com",
  "zoho.com",
  "gmx.com",
  "rediffmail.com",
  "naver.com",
  "daum.net",
  "nate.com",
  "hanmail.net",
  "yahoo.co.kr",
  "qq.com",
  "163.com",
  "126.com",
  "sina.com",
  "sohu.com",
  "vip.163.com",
  "yeah.net",
  "aliyun.com",
  "tencent.com",
  "foxmail.com",
];

const ACTION_TYPE_COMPANY_USER = {
  CALL_PHONE: "CALL_PHONE",
  SEND_EMAIL_APPLICATION: "SEND_EMAIL_APPLICATION",
  SCHEDULE_INTERVIEW: "SCHEDULE_INTERVIEW",
  ACCEPT_APPLICATION: "ACCEPT_APPLICATION",
};

const PATH_ROUTER = {
  LANDING_PAGE: "/",
  AUTH_CALLBACK: "/auth-callback",
  SEARCH: "/search",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  HOME: "/dash-board/home",
  APPLICATION: "/dash-board/application",
  PROFILE: "/dash-board/profile",
  NOTICE: "/dash-board/notice",
  APPLICATION_FORM: "/application-form",
  APPLICATION_FORM_REVISE: "/application-form-revise",
  APPLICATION_FORM_REVIEW: "/application-form-review",
  COMPANY: "/company",
  STUDENT: "/student",
};

const FORMAT_DATE = {
  MM_DD_YYYY: "MM/DD/YYYY",
  MMM_D_YYYY: "MMM D, YYYY",
  MMM_D: "MMM D",
  MMM_YYYY: "MMM YYYY",
  MM_YYYY: "MM/YYYY",
};

export {
  ACCEPT_FILE_TYPES,
  ACTION_TYPE_COMPANY_USER,
  FORMAT_DATE,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_IMAGE,
  PATH_ROUTER,
  bannedDomains,
};
