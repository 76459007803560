interface Config {
  apiUrl: string;
  env: string;
}

const config: Config = {
  apiUrl: import.meta.env.VITE_API_URL,
  env: import.meta.env.VITE_ENV,
};

export default config;
