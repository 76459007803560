import { message } from "antd";
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import config from "../config";
import { PATH_ROUTER } from "../constants";
import { isTokenExpired } from "../utils";
import { safeNavigate } from "../utils/helper";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

axiosInstance.interceptors.request.use(
  (configAxios: InternalAxiosRequestConfig) => {
    const publicEndpoints: string[] = [`${config.apiUrl}timezones`];

    if (!publicEndpoints.includes(configAxios.url!)) {
      const token = Cookies.get("user_token");
      if (token) {
        if (isTokenExpired(token)) {
          message.error("Login session has expired! Please login again!");
          safeNavigate(PATH_ROUTER.SIGN_IN);
        } else {
          if (!configAxios.headers) {
            configAxios.headers = new AxiosHeaders();
          }
          if (configAxios.headers instanceof AxiosHeaders) {
            configAxios.headers.set("Authorization", `Bearer ${token}`);
          }
        }
      }
    }

    return configAxios;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response?.status;

    // Handle 401 Unauthorized or 302 Redirect
    if (status === 401 || status === 302) {
      message.error(
        "Session expired or unauthorized access. Redirecting to sign-in..."
      );
      safeNavigate(PATH_ROUTER.SIGN_IN);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
