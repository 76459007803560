import { Drawer } from "antd";
import classNames from "classnames";
import React from "react";

interface IDrawerProps {
  placement: "left" | "top" | "bottom" | "right";
  content: React.ReactNode;
  title?: React.ReactNode;
  open: boolean;
  className?: string;
  closeable?: boolean;
  onclose?: () => void;
  size?: "default" | "large";
  width?: string | number;
  height?: string | number;
  footer?: React.ReactNode;
}

const DrawerComponent: React.FC<IDrawerProps> = ({
  placement,
  content,
  title,
  open,
  className,
  closeable = false,
  onclose,
  width,
  height,
  size = "default",
  footer,
}) => {
  return (
    <>
      <Drawer
        className={classNames(className, "btn-custom")}
        title={title}
        placement={placement}
        closable={closeable}
        onClose={onclose}
        open={open}
        key={placement}
        size={size}
        footer={footer}
        width={width}
        height={height}
      >
        {content}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
