import React, { useRef } from "react";
import { Carousel } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import {
  ChristineZhouAvatar,
  DavidLeeAvatar,
  JenniferTaylorAvatar,
  JiyoungKimAvatar,
  RobertClarkAvatar,
  SoraYoonAvatar,
} from "../../../../assets/png";
import { QuoteIcon } from "../../../../assets/svg";

import "./review.s.scss";
import { CarouselRef } from "antd/es/carousel";

interface Author {
  name: string;
  position: string;
  avatar: string;
  jobType: string;
}

interface Testimonial {
  comment: string;
  author: Author;
  tags: string[];
}

const ReviewSection: React.FC = () => {
  const carouselRef = useRef<CarouselRef | null>(null);

  const testimonials: Testimonial[] = [
    {
      comment:
        "I'm a freshman at the university this year. I spent three months looking for a summer internship but initially failed because no one was interested in hiring a freshman. This month, I tried the O-CA program on the Lion-up platform, where I spoke with a startup co-founder, expressed my passion for the work and industry, and finally secured an opportunity to gain work experience.",
      author: {
        name: "Jiyoung KIM",
        position: "Software Engineering Intern",
        avatar: JiyoungKimAvatar,
        jobType: "O-CA Program",
      },
      tags: ["Software Engineering", "Freshman", "Summer Internship"],
    },
    {
      comment:
        "The O-CA program on the Lion-Up platform allowed me to speak with a startup co-founder, negotiate my tasks and schedule, and ultimately gain valuable work experience. Thank you for providing me with such a great opportunity.",
      author: {
        name: "Christine Zhou",
        position: "Associate Product Manager",
        avatar: ChristineZhouAvatar,
        jobType: "O-CA Program",
      },
      tags: ["Product Manager", "Part-time", "Negotiable"],
    },
    {
      comment:
        "I'm a master’s program student, and I wanted to gain practical experience while attending school. However, most part-time positions required a minimum commitment of 20 hours per week, and with my studies, it was difficult to dedicate more than 15 hours. Through the O-CA Program, I was able to negotiate with startup representatives and successfully arranged a practical experience role with a 10-hour weekly commitment for a year. I was thrilled to find an opportunity that fit my situation so well.",
      author: {
        name: "Robert Clark",
        position: "AL/ML Engineer",
        avatar: RobertClarkAvatar,
        jobType: "O-CA Program",
      },
      tags: ["AL/ML", "Part-time", "O-CA Program"],
    },
    {
      comment:
        "Thank you, O-CA Program, and thank you, Lion-Up! I received a recommendation letter for my Ph.D. application from the CEO of the company where I completed the O-CA Program, and I was ultimately accepted. The O-CA Program provided me with the exact experience I needed, tailored just for me.",
      author: {
        name: "Jennifer Taylor",
        position: "AI Modeling developer",
        avatar: JenniferTaylorAvatar,
        jobType: "O-CA Program",
      },
      tags: ["Recommendation", "AL/ML", "O-CA Program"],
    },
    {
      comment:
        "I’ve always been curious about how American companies operate and their work styles. However, due to financial constraints, I couldn’t study abroad to gain experience with a U.S. company. Through the O-CA Program, I was able to negotiate a remote position with an American startup, allowing me to gain practical experience for three months over the summer break. I’m thrilled to have the opportunity to experience the American work style while staying in Korea.",
      author: {
        name: "Sora Yoon",
        position: "UI/UX Design Internship",
        avatar: SoraYoonAvatar,
        jobType: "O-CA Program",
      },
      tags: ["UI/UX", "Part-time", "Overseas"],
    },
    {
      comment:
        "I found a startup with an inspiring vision and applied to the O-CA Program to work with them. After three months, I transitioned to an internship position, and three months later, they offered a return offer after I graduated from college. I truly enjoy working with them, their work style, and their product. I believe the O-CA Program offers a valuable opportunity for both employees and employers to understand each other. Now, I can’t wait to graduate from college!",
      author: {
        name: "David Lee",
        position: "Data Scientist",
        avatar: DavidLeeAvatar,
        jobType: "O-CA Program",
      },
      tags: ["Data Scientist", "Return Offer", "O-CA Program"],
    },
  ];

  const carouselSettings = {
    ref: carouselRef,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <section className="reviews">
      <div className="container">
        <div className="section-header">
          <h2 className="section-heading">Loved by our community</h2>
        </div>

        <div className="reviews-carousel">
          <Carousel {...carouselSettings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <div className="review-card">
                  <h3 className="position">{testimonial.author.position}</h3>
                  <div className="quote-icon">
                    <img width={31} src={QuoteIcon} alt="" />
                  </div>
                  <p className="content">{testimonial.comment}</p>

                  <div className="tags">
                    {testimonial.tags.map((tag, tagIndex) => (
                      <span key={tagIndex} className="tag">
                        {tag}
                      </span>
                    ))}
                  </div>

                  <div className="author">
                    <img
                      src={testimonial.author.avatar}
                      alt={testimonial.author.name}
                      className="avatar"
                    />
                    <div className="author-info">
                      <div className="name">{testimonial.author.name}</div>
                      <div className="program">
                        {testimonial.author.jobType}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          <div className="carousel-actions">
            <button className="slick-arrow slick-prev" onClick={prev}>
              <ArrowLeftOutlined />
            </button>
            <button className="slick-arrow slick-next" onClick={next}>
              <ArrowRightOutlined />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
