import { EnvironmentOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import _ from "lodash";
import { LoadingGif } from "../../../../assets/gif";
import ButtonComponent from "../../../../components/button/button";
import DrawerComponent from "../../../../components/drawer/drawer";
import { PATH_ROUTER } from "../../../../constants";
import { fetchListLocation } from "../../../../services/fetchListLocation";
import { fetchSearchComplete } from "../../../../services/fetchSearchComplete";
import useMergeState from "../../../../utils/customHook/useMergeState";
import { safeNavigate } from "../../../../utils/helper";
import "./hero.s.scss";

const Hero: React.FC = () => {
  const [state, setState] = useMergeState({
    searchJob: "",
    listAutoComplete: [],
    searchLocation: [],
    listLocation: [],
    isLoading: true,
    openDrawerFindJob: false,
  });

  const getListAutoComplete = async (text: string) => {
    try {
      const autoCompletes = await fetchSearchComplete(text, 0, 6);
      if (!_.isEmpty(autoCompletes)) {
        const listAutoComplete = _.map(autoCompletes, (item) => ({
          value: item.value,
          label: (
            <>
              <SearchOutlined style={{ marginRight: 6 }} /> {item.label}
            </>
          ),
        }));
        setState({ listAutoComplete });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListLocation = async (text: string) => {
    try {
      const locations = await fetchListLocation(text, 0, 6);
      if (!_.isEmpty(locations)) {
        const listLocation = _.map(locations, (item) => ({
          id: item.id,
          value: item.value,
          label: (
            <>
              <EnvironmentOutlined style={{ marginRight: 6 }} /> {item.label}
            </>
          ),
        }));
        setState({ listLocation });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onChangeJob = (value: string) => {
    setState({ searchJob: value });
  };

  const onChangeLocation = (value: string, option: any) => {
    setState({ searchLocation: option.id, valueLocation: value });
  };

  const onSearch = () => {
    const { searchJob, searchLocation, valueLocation } = state;
    const cityId = searchLocation?.[0] | 0;
    const stateId = searchLocation?.[1] | 0;
    safeNavigate(PATH_ROUTER.SEARCH, {
      state: { filterSearch: { searchJob, cityId, stateId, valueLocation } },
    });
  };

  const handleWaiting = () => {
    setState({ isLoading: true });
  };

  const handleCanPlay = () => {
    setState({ isLoading: false });
  };

  return (
    <>
      <DrawerComponent
        title="Search Jobs"
        closeable
        placement="bottom"
        className="drawer-search-jobs"
        height={"92vh"}
        open={state.openDrawerFindJob}
        onclose={() => setState({ openDrawerFindJob: false })}
        content={
          <div className="search-job-content">
            <AutoComplete
              className="auto-completed-custom"
              style={{ fontWeight: 400 }}
              onSearch={(text) => getListAutoComplete(text)}
              onChange={onChangeJob}
              options={state.listAutoComplete}
              value={state.searchJob}
            >
              <Input
                allowClear
                size="large"
                placeholder="Find your perfect experience"
                style={{ fontSize: 16 }}
                prefix={
                  <SearchOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
            <AutoComplete
              className="auto-completed-custom"
              style={{ fontWeight: 400, fontSize: "16px" }}
              onSearch={(text) => getListLocation(text)}
              onChange={onChangeLocation}
              options={state.listLocation}
              value={state.valueLocation}
            >
              <Input
                allowClear
                size="large"
                placeholder="City, country"
                style={{ fontSize: 16 }}
                prefix={
                  <EnvironmentOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
          </div>
        }
        footer={
          <div className="drawer-footer-action">
            <ButtonComponent
              className="clear-btn"
              onClick={() => {
                setState({ searchJob: "", valueLocation: "" });
              }}
              title="Clear"
            />
            <ButtonComponent
              className="search-btn"
              title="Search"
              onClick={() => {
                onSearch();
                setState({ openDrawerFindJob: false });
              }}
            />
          </div>
        }
      />
      <section className="hero">
        <div className="hero-main">
          <div className="container">
            <div className="hero-content">
              <h1 className="heading">
                Kickstart your Career.
                <br />
                Lion-up supports you.
              </h1>
              <p className="description">
                Explore a variety of positions and find the perfect fit for your
                future
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="search">
            <Input
              readOnly
              className="input-search-mobile"
              allowClear
              value={state.searchJob}
              size="large"
              placeholder="Find your perfect experience"
              prefix={
                <SearchOutlined style={{ marginRight: 6, color: "#0F172A" }} />
              }
              onClick={() => {
                setState({ openDrawerFindJob: true });
              }}
            />
            <AutoComplete
              className="search-title"
              style={{ width: 350, fontWeight: 400 }}
              onSearch={(text) => getListAutoComplete(text)}
              onChange={onChangeJob}
              options={state.listAutoComplete}
              value={state.searchJob}
            >
              <Input
                className="search-title"
                allowClear
                size="large"
                placeholder="Find your perfect experience"
                prefix={
                  <SearchOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
            <AutoComplete
              className="search-location"
              style={{ width: 350, fontWeight: 400 }}
              onSearch={(text) => getListLocation(text)}
              onChange={onChangeLocation}
              options={state.listLocation}
              value={state.valueLocation}
            >
              <Input
                className="search-location"
                allowClear
                size="large"
                placeholder="City, country"
                prefix={
                  <EnvironmentOutlined
                    style={{ marginRight: 6, color: "#0F172A" }}
                  />
                }
              />
            </AutoComplete>
            <ButtonComponent
              className="search-btn"
              title="Search"
              type="primary"
              size="large"
              onClick={onSearch}
            />
          </div>
        </div>

        <div className="container">
          <div className="hero-video">
            <div
              style={{
                position: "relative",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              {state.isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "10px 20px",
                    zIndex: 1,
                  }}
                >
                  <img src={LoadingGif} height={40} alt="loading" />
                </div>
              )}
              <video
                autoPlay
                muted
                loop
                playsInline
                className="content-video"
                onWaiting={handleWaiting}
                onCanPlay={handleCanPlay}
              >
                <source
                  src="https://assets.lion-up.net/public/videos/introduce.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
