import React from "react";
import { Tabs } from "antd";
import {
  FindIndustryImage,
  FindPositionImage,
  GetBenefitImage,
  NegotiableImage,
} from "../../../../assets/png";
import "./discover.s.scss";

interface TabContentProps {
  title: string;
  description: React.ReactNode;
  image?: string;
}

interface TabConfig {
  label: string;
  key: string;
  content: TabContentProps;
}

const DiscoverContent: React.FC<TabContentProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <div className="tab-content">
      <div className="section-header">
        <h2 className="section-heading">{title}</h2>
        <p className="section-subheading">{description}</p>
      </div>
      <div className="content-image">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};

const DiscoverSection: React.FC = () => {
  const tabConfigurations: TabConfig[] = [
    {
      label: "Find Position",
      key: "position",
      content: {
        title: "Discover your ideal role",
        description:
          "Explore various positions tailored to your career goals and kickstart your journey in the industry you're passionate about.",
        image: FindPositionImage,
      },
    },
    {
      label: "Find Industry",
      key: "industry",
      content: {
        title: "Navigate your dream industry",
        description:
          "Identify the industries that align with your interests and skills, opening up opportunities for long-term growth and success.",
        image: FindIndustryImage,
      },
    },
    {
      label: "Negotiable",
      key: "negotiable",
      content: {
        title: "Adjust the conditions to fit your needs",
        description:
          "You can negotiate certain conditions with the companies, such as working hours, location, tasks, and more. This allows you to customize your experience and create a working arrangement that best fits your needs and goals.",
        image: NegotiableImage,
      },
    },
    {
      label: "Get Benefits",
      key: "benefits",
      content: {
        title: "Unlock exclusive benefits",
        description:
          "Secure a recommendation letter from CEOs to enhance your school application. Additionally, before joining the company as a full-time employee, you can experience its culture and work style to determine if it's the right fit for you.",
        image: GetBenefitImage,
      },
    },
  ];

  const tabItems = tabConfigurations.map((tab) => ({
    label: <span className="tab-label">{tab.label}</span>,
    key: tab.key,
    children: <DiscoverContent {...tab.content} />,
  }));

  return (
    <section className="discover">
      <div className="container">
        <Tabs
          defaultActiveKey="search"
          items={tabItems}
          className="discover-tabs"
        />
      </div>
    </section>
  );
};

export default DiscoverSection;
