import React from "react";
import "./navbar.s.scss";
import { Link } from "react-router-dom";
import { Logo } from "../../../../assets/svg";

const Navbar: React.FC = () => {
  return (
    <div className="navbar">
      <div className="container">
        <div className="navbar-content">
          <Link to="/" className="navbar-logo">
            <img src={Logo} alt="Lion-up Logo" />
          </Link>
          <div className="navbar-actions">
            <Link to="/sign-in" className="get-started-btn">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
