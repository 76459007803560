import "./guidelines.s.scss";

const Guidelines: React.FC = () => {
  const steps = [
    {
      number: "01",
      title: "Build your Profile",
      description:
        "Create your own profile and highlight your strengths! It's important to showcase your unique skills and experiences!",
      video: "https://assets.lion-up.net/public/videos/profile.mp4",
    },
    {
      number: "02",
      title: "Find the experience",
      description:
        "Utilize the search function to explore and find experiences related to the position and industry you aspire to work in.",
      video: "https://assets.lion-up.net/public/videos/search.mp4",
    },
    {
      number: "03",
      title: "Negotiate conditions",
      description:
        "It's okay if it's not the exact position you wanted. Look for positions marked as \"negotiable\" and try negotiating with the company!",
      video: "https://assets.lion-up.net/public/videos/negotiable.mp4",
    },
  ];

  return (
    <section className="guidelines">
      <div className="container">
        <div className="section-header">
          <h2 className="section-heading">Get started with our platform</h2>
          <p className="section-subheading">
            Effortless, efficient, and tailored to your goals.
          </p>
        </div>

        <div className="guidelines-content">
          {steps.map((step, index) => (
            <div key={index} className="guideline-item">
              <div className="guideline-info">
                <span className="step-number">
                  {step.number}
                  <span className="step-dot">.</span>
                </span>
                <div className="step-content">
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
              <div className="guideline-video">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="content-video"
                  webkit-playsinline="true"
                  preload="auto"
                >
                  <source src={step.video} type="video/mp4" />
                </video>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Guidelines;
